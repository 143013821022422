import { Fragment, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/none';

import LoginContext from 'utils/gatsby/context/login-status';
import { useFlagAwait } from 'utils/hooks/use-flagsawait';
import { FDT_285, FDT_285_DISPLAY_PLANS } from 'utils/launch-darkly/experiments/constants';

import { SignUpPlanIds } from 'components/forms/constants';
import SignupForm from 'components/forms/signup-full';
import Yoast from 'components/globals/yoast';

import './styles/signup.scss';
export const Head = () => (
  <Fragment>
    <meta name="robots" content="noindex" />
    <meta name="robots" content="nofollow" />
  </Fragment>
);

export default function FreeTrialEnterprise() {
  const fdt285 = useFlagAwait(FDT_285);
  const fdt285DisplayPlans = useFlagAwait(FDT_285_DISPLAY_PLANS);
  const { loggedIn } = useContext(LoginContext);
  const pricingFlagValue = loggedIn ? fdt285DisplayPlans : fdt285;

  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "free-trial-enterprise" } }) {
        edges {
          node {
            wordpress_id
            acf {
              sign_up {
                signup_modal_submit
                legal
                features {
                  text
                  image {
                    source_url
                  }
                }
                features_title
              }
            }
            content
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const content = data.allWordpressPage.edges[0].node.content;
  const featuresTitle = pageContext.sign_up.features_title;

  const refPlanId = pricingFlagValue === 'pricing-2024-v2' ? SignUpPlanIds.PREMIUM_TRIAL_2024v2 : SignUpPlanIds.PREMIUM_TRIAL;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <section className="signup-page-container light-grey" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <div className="container container-xlarge light-grey">
          <div className="signup-grid">
        
            <div className="signup-column" data-section="signupSection">
              <img
                className="logo is-hidden-tablet"
                src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
              />
              {pricingFlagValue !== undefined && <Fragment> <h2 className="title">Try everything—start your free trial today.</h2>
                <SignupForm
                  uniqueId="freeTrialEnterprise"
                  legalText={ pageContext.sign_up.legal }
                  signupModalSubmit={ pageContext.sign_up.signup_modal_submit }
                  refPlanId={ refPlanId }
                />
              </Fragment>}
            </div>


            <div className="marketing-column">
              <div className="marketing-column-top">
                <img
                  className="logo is-hidden-mobile"
                  src="https://marketing-assets.wheniwork-production.com/2019/08/15163825/logo.svg"
                />

                <div
                  className="marketing-copy"
                  dangerouslySetInnerHTML={ { __html: content } }
                />
              </div>
              <div className="marketing-column-bottom">
                <h3 className="title">{featuresTitle}</h3>
                <ul className="features">
                  {data.allWordpressPage.edges[0].node.acf.sign_up.features.map(
                    (feature, index) => {
                      return (
                        <li key={ index } className="feature">
                          <div className="feature-content">
                            <img
                              className="feature-icon"
                              src={ feature.image.source_url }
                            />
                            <p>{feature.text}</p>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
